.glassmorph {
  background-color: rgba(255, 255, 255, 0.7);
  -webkit-backdrop-filter: blur(4px);
  backdrop-filter: blur(4px);
}

@media screen and (max-width: 1280) {
  .glassmorph {
  }
}

.active {
  color: var(--color-blue);
}
