.container {
  max-width: 1140px;
}

footer {
  position: relative;
  &::after {
    content: '';
    position: absolute;
    width: 100%;
    background-color: var(--color-dark-blue);
    -webkit-mask-image: url(../../assets/img/footer-mask.svg);
    -webkit-mask-repeat: no-repeat;
    -webkit-mask-size: 100% 100%;
    inset: 0;
    z-index: -1;
  }
  .premium-shadow {
    filter: drop-shadow(0 10px 60px rgba(15, 15, 15, 0.08));
  }
}
