:host {
  display: flex;
  align-items: center;
  ::ng-deep .mat-mdc-form-field-subscript-wrapper {
    height: 0;
  }

  ::ng-deep .mat-mdc-select-arrow-wrapper {
    display: none;
  }

  ::ng-deep .mdc-notched-outline {
    opacity: 0;
  }

  ::ng-deep .mat-mdc-select-min-line {
    display: none;
  }
}
